/* istanbul ignore file (entry file cannot be tested) */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'core-js'

import('../main/init')
	.then(({ init }) => init({ serviceWorker: true }))
	.then(() => {
		document.querySelector('html')!.classList.add('loaded')
	})
	.catch((error) => {
		document.querySelector('html')!.classList.add('error')
		// eslint-disable-next-line no-console
		console.groupCollapsed('Startup failed')
		// eslint-disable-next-line no-console
		console.error(error)
		// eslint-disable-next-line no-console
		console.groupEnd()

		const errorDisplay: HTMLElement = document.querySelector('p.error-message') ?? document.createElement('p')
		errorDisplay.className = 'error-message'
		errorDisplay.innerText = error.message
		document.querySelector('#browser-support')?.appendChild(errorDisplay)
		throw error
	})
	.finally(() => {
		document.querySelector('html')!.classList.remove('loading')
	})
